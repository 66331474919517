import React from 'react'

const Location = () => {
  return (
    <svg width="21.6475" height="21.2915">
      <g>
        <rect height="21.2915" opacity="0" width="21.6475" x="0" y="0"/>
        <path d="M10.6392 21.2783C16.4531 21.2783 21.2915 16.4531 21.2915 10.6392C21.2915 4.8252 16.4399 0 10.626 0C4.8252 0 0 4.8252 0 10.6392C0 16.4531 4.83838 21.2783 10.6392 21.2783ZM10.6392 19.2744C5.85352 19.2744 2.03027 15.438 2.03027 10.6392C2.03027 5.84033 5.84033 2.00391 10.626 2.00391C15.4248 2.00391 19.2744 5.84033 19.2744 10.6392C19.2744 15.438 15.438 19.2744 10.6392 19.2744Z" fill="black" />
        <path d="M5.00977 11.6147L9.12305 11.6147C9.36035 11.6147 9.50537 11.7598 9.50537 11.9971L9.50537 16.0576C9.50537 17.0991 10.5864 17.2046 10.9292 16.4531L15.4248 6.85547C15.8335 5.98535 15.082 5.20752 14.1855 5.62939L4.61426 10.1909C3.87598 10.5337 3.98145 11.6147 5.00977 11.6147Z" fill="black" />
      </g>
    </svg>
  )
}

export default Location